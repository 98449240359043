
export default [
  {
    key: 'pampa_limit',
    labelKey: 'pampa_limit',
    color: '#B14018',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'adaptación baseada em Soriano et al. 1992, IBGE 2019 y Oyarzabal et al. 2018',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/PAMPA_TRINACIONAL_LIMIT.zip',
    index: 1,
  },
  {
    key: 'country',
    labelKey: 'country',
    color: '#B024E0',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'adaptación en base a Instituto Geográfico Nacional, 2019 (AR), IBGE 2019 (BR) y IDEUy, 2022 (UY)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/POLITICAL_LEVEL_1.zip',
    index: 2,
  },
  {
    key: 'state',
    labelKey: 'state',
    color: '#3A3D3A',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'adaptación en base a Instituto Geográfico Nacional, 2019 (AR), IBGE 2019 (BR) y IDEUy, 2022 (UY)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/POLITICAL_LEVEL_2.zip',
    index: 3,
  },
  {
    key: 'municipality',
    labelKey: 'municipality',
    color: '#F4B615',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'adaptación em base a Instituto Geográfico Nacional, 2019 (AR), IBGE 2019 (BR), Ministerio de Ganadería, Agricultura y Pesca, 2011 (UY)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/POLITICAL_LEVEL_3.zip',
    index: 4,
  },
  {
    key: 'watershed',
    labelKey: 'watershed',
    color: '#16DFF2',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'INTA, 2002 (AR), ANA, 2019 (BR), Ministério de Ambiente, 2017 (UY)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2024',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/BASIN_LEVEL_1.zip',
    index: 5,
  },
  {
    key: 'phytogeography',
    labelKey: 'phytogeography',
    color: '#FDAE61',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Oyarzabal et al. 2018',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2018',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/PHYTOGEOGRAPHIC_PROVINCES.zip',
    index: 6,
  },
  {
    key: 'protected_areas',
    labelKey: 'protected_areas',
    color: '#1A7836',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'INTA, 2002(AR), MMA, 2019 (BR), Ministério de Ambiente, 2017 (UY)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2017',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/PROTECTED_AREA.zip',
    index: 7,
  },
  {
    key: 'quilombo',
    labelKey: 'quilombo',
    color: '#C51B7D',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'INCRA, 2023',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2023',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/QUILOMBOS.zip',
    index: 8,
  },
  {
    key: 'indigenous_territory',
    labelKey: 'indigenous_territory',
    color: '#FF0100',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'FUNAI, 2023',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2023',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/pampa/collection_4/download/layers/INDIGENOUS_TERRITORIES.zip',
    index: 9,
  },
];
